import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, useLocation } from 'react-router-dom';
import moment from 'moment';
import styles from './Preview.module.scss';
import { Card, Table, Avatar, RadarChart, StarRating } from 'components/template';
import { FaLongArrowAltRight } from 'react-icons/fa';
import Helpers from 'shared/helpers';
import TeamRow from 'components/TeamRow/TeamRow';
import IcoTempo from 'assets/ico/ico-tempo.png';
import IcoJogos from 'assets/ico/ico-jogos.png';
import IcoTitular from 'assets/ico/ico-titular.png';
import IcoEntrou from 'assets/ico/ico-entrou.png';
import IcoSaiu from 'assets/ico/ico-saiu.png';
import IcoGols from 'assets/ico/ico-gols.png';
import IcoAssistencias from 'assets/ico/ico-assistencias.png';
import IcoAmarelos from 'assets/ico/ico-amarelos.png';
import IcoAmareloVermelho from 'assets/ico/ico-amarelo-vermelho.png';
import IcoVermelhos from 'assets/ico/ico-vermelhos.png';
import ReportAppBar from 'components/ReportAppBar/ReportAppBar';
import FieldList from 'pages/Athlete/Evaluation/FieldList/FieldList';
import { getAthleteEvaluation } from 'store/ducks/evaluations';
import { useTranslation } from 'react-i18next';
import CustomFields from 'pages/Athlete/Evaluation/CustomFields/CustomFields';
import { FaEye, FaRegCalendarAlt, FaFileAlt } from 'react-icons/fa';
import { IoIosFootball } from 'react-icons/io';

let _hasExported = false;
function Preview({ configuration, athlete, athleteEditableData, reportData }) {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const athleteData = athlete?.athlete ? { ...athlete?.athlete } : null;
  const evaluationsData = athlete?.evaluations ? [...athlete?.evaluations] : [];
  const historicalData = athlete?.historical ? [...athlete?.historical] : [];
  const transfersData = athlete?.transfers ? [...athlete?.transfers] : [];
  const contractsData = athlete?.contracts ? [...athlete?.contracts] : [];
  const location = useLocation();
  const exportReport = location.state?.export;
  const [fieldsEvaluation, setFieldsEvaluation] = useState([]);
  useEffect(() => {
    if (configuration.evaluations && athleteEditableData.evaluationId && fieldsEvaluation.length === 0) {
      getAthleteEvaluation(athleteEditableData.id, athleteEditableData.evaluationId).then(data => {
        if (data && data.error === undefined && data.data) {
          const dataFields = data?.data?.values?.fields ?? [];
          dataFields.forEach((df, index) => {
            if (index < athleteEditableData.microaspects.length) {
              df.feedback = athleteEditableData.microaspects[index];
            }
          });
          setFieldsEvaluation(dataFields);
        }
      });
    }
  }, [configuration, athleteEditableData, fieldsEvaluation]);

  const [generalRate, setGeneralRate] = useState(0);

  useEffect(() => {
    if (fieldsEvaluation?.length) {
      const sumRates = fieldsEvaluation.map(f => f?.rate ?? 0).reduce((acc, rate) => acc + rate);
      setGeneralRate(sumRates / fieldsEvaluation?.length);
    }
  }, [fieldsEvaluation]);

  if (exportReport && !_hasExported) {
    setTimeout(() => window.print(), 600);
    _hasExported = true;
  }

  const athleteMainInfo = (
    <Card softShadow className={styles.mainInfo}>
      <div className={styles.athletePhotoDiv}>
        <div className={styles.athletePhoto} style={{ backgroundImage: `url(${athleteEditableData.photo})` }}></div>
      </div>
      <div className={styles.athleteInfo}>
        <div className={styles.generalData}>
          <div className={styles.leftContent}>
            <div className={styles.athleteName}>
              <p>{athleteEditableData.nickname}</p>
              <span>{athleteEditableData.fullname}</span>
            </div>
            <div className={styles.athleteAge}>
              <div className={styles.formGroup}>
                <div>
                  <a
                    href={`/atletas/${athleteEditableData.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: '#000' }}
                  >
                    <p>{athleteEditableData.nickname}</p>
                  </a>
                  <span>{athleteEditableData.position}</span>
                </div>
                <div style={{ borderLeft: '1px solid black', height: '12px' }} />
                <div>
                  <p>{t('athlete_list.box3')}:</p>
                  <span>
                    '{athleteData.birthyear % 100} ({Helpers.getAge(athleteData.birthdate)} {t('athlete_card.idade')})
                  </span>
                </div>
                <div style={{ borderLeft: '1px solid black', height: '12px' }} />
                {athleteEditableData.nationalities?.length
                  ? athleteEditableData.nationalities.map(item => (
                      <>
                        <p>{t('athlete_card.nation')}:</p>
                        <div>
                          <span style={{ fontWeight: '600' }}>{item.name}</span>
                          <img src={item.flag} alt={`Bandeira do ${item.name}`} key={item.name} />
                        </div>
                      </>
                    ))
                  : ''}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.aditionalData}>
          <div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.birth')}</label>
              <span>{athleteEditableData.birthdate && moment(athleteEditableData.birthdate).format('DD/MM/YYYY')}</span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_list.box3')}:</label>
              <span>
                {athleteEditableData.age} {t('athlete_card.idade')}
              </span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.height')}</label>
              <span>{(athleteEditableData.height && athleteEditableData.height + 'cm') || '-'}</span>
            </div>
          </div>
          <div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.pos')}</label>
              <span>{athleteEditableData.position}</span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.foot')}</label>
              <span>{athleteEditableData.foot}</span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('club.text14')}:</label>
              <span>{(athleteEditableData.weight && athleteEditableData.weight + 'kg') || '-'}</span>
            </div>
          </div>
          {(athleteData.current_team || athleteData.contract_info?.current_contract_end) && (
            <div className={styles.clubInfo}>
              {athleteData.current_team && (
                <div className={styles.formGroup}>
                  <p>{t('agencies.text2')}:</p>
                  <span className={styles.flags}>
                    <img
                      src={athleteData.current_team?.country?.flag}
                      alt={`Bandeira do ${athleteData.current_team?.country?.name}`}
                    />
                    {athleteData.current_team?.emblem ? (
                      <img
                        src={athleteData.current_team?.emblem}
                        alt={`Bandeira do ${athleteData.current_team?.country?.nickname}`}
                      />
                    ) : (
                      <Avatar
                        img={athleteData.current_team?.emblem}
                        name={athleteData.current_team?.nickname}
                        className={styles.avatar}
                      />
                    )}
                    <span>{athleteData.current_team?.nickname}</span>
                  </span>
                </div>
              )}
              {athleteData.contract_info?.current_contract_end && (
                <div className={styles.formGroup}>
                  <label>{t('athlete_list.box6')}:</label>
                  <span>{moment(athleteData.contract_info.current_contract_end).format('DD/MM/YYYY')}</span>
                </div>
              )}
            </div>
          )}
          {(athleteData.src_team || athleteData.contract_info?.src_contract_end) && (
            <div className={styles.clubInfo}>
              {athleteData.src_team && (
                <div className={styles.formGroup}>
                  <label>{t('agencies.text4')}:</label>
                  <span className={styles.flags}>
                    <img
                      src={athleteData.src_team?.country?.flag}
                      alt={`Bandeira do ${athleteData.src_team?.country?.name}`}
                    />
                    {athleteData.src_team?.emblem ? (
                      <img
                        src={athleteData.src_team?.emblem}
                        alt={`Bandeira do ${athleteData.src_team?.country?.nickname}`}
                      />
                    ) : (
                      <Avatar
                        img={athleteData.src_team?.emblem}
                        name={athleteData.src_team?.nickname}
                        className={styles.avatar}
                      />
                    )}
                    <span>{athleteData.src_team?.nickname}</span>
                  </span>
                </div>
              )}
              {athleteData.contract_info?.src_contract_end && (
                <div className={styles.formGroup}>
                  <label>{t('athlete_list.box6')}:</label>
                  <span>{moment(athleteData.contract_info?.src_contract_end).format('DD/MM/YYYY')}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Card>
  );

  const athleteHistorical =
    historicalData &&
    historicalData.map(row => {
      const season = row.season;
      const team = (
        <TeamRow name={row.team?.nickname} id={row.team?.id} img={row.team?.emblem} className={styles.teamRow} />
      );
      const competitionName = row.competition_name;
      const minutesPlayed = row.minutes_played;
      const appearances = row.appearances;
      const lineups = row.lineups;
      const subsIn = row.subs_in;
      const subsOut = row.subs_out;
      const goals = row.goals;
      const assists = row.assists;
      const yellowCards = row.yellow_cards;
      const secondYellow = row.second_yellow_cards;
      const redCards = row.red_cards;

      return [
        season,
        team,
        competitionName,
        minutesPlayed,
        appearances,
        lineups,
        subsIn,
        subsOut,
        goals,
        assists,
        yellowCards,
        secondYellow,
        redCards,
      ];
    });

  let athleteTransfersTotal = 0;
  const athleteTransfers =
    transfersData &&
    transfersData.map(row => {
      const season = row.season;
      const date = row.transfer_date && moment(row.transfer_date).format('DD/MM/YYYY');
      const srcTeam = row.src_team && (
        <div className={styles.flagsDiv}>
          {row.src_team?.country?.flag && (
            <img
              className={styles.countryFlag}
              src={row.src_team?.country?.flag}
              alt={row.src_team?.country?.name ?? ''}
            />
          )}
          <TeamRow
            name={row.src_team?.nickname}
            id={row.src_team?.id}
            img={row.src_team?.emblem}
            className={styles.teamRow}
          />
        </div>
      );
      const dstTeam = row.dst_team && (
        <div className={styles.flagsDiv}>
          <FaLongArrowAltRight />
          {row.dst_team?.country?.flag && (
            <img
              className={styles.countryFlag}
              src={row.dst_team?.country?.flag}
              alt={row.dst_team?.country?.name ?? ''}
            />
          )}
          <TeamRow
            name={row.dst_team?.nickname}
            id={row.dst_team?.id}
            img={row.dst_team?.emblem}
            className={styles.teamRow}
          />
        </div>
      );
      const age = `${row.age_at_date.years} ${t('athlete_card.idade')}, ${row.age_at_date.months} ${t(
        'athlete_card.meses'
      )} ${row.age_at_date.days} ${t('athlete_card.days')}`;
      const value = row.transfer_value;

      if (row.monetary_value !== null) athleteTransfersTotal = athleteTransfersTotal + row.monetary_value;

      return [season, date, srcTeam, dstTeam, age, value];
    });

  const athleteContracts =
    contractsData &&
    contractsData.map(row => {
      const bidDate = row.contract_info?.bid_date && moment(row.contract_info?.bid_date).format('DD/MM/YYYY');
      const team = (
        <TeamRow name={row.team?.nickname} id={row.team?.id} img={row.team?.emblem} className={styles.teamRow} />
      );
      const state = row.contract_info?.state;
      const type = row.contract_info?.type;

      const contractActive = moment(row.contract_info.end, 'YYYY-MM-DD')._d >= moment();
      const contract = row.contract_info ? (
        <span className={styles.contractStatus}>
          {contractActive ? (
            <div className={`${styles.dotIcon} ${styles.purpleDot}`}></div>
          ) : (
            <div className={styles.dotIcon}></div>
          )}
          {row.contract_info.contract}
        </span>
      ) : (
        ''
      );
      const start = row.contract_info?.start && (
        <span className={contractActive ? styles.contractActive : styles.contractInactive}>
          {moment(row.contract_info?.start).format('DD/MM/YYYY')}
        </span>
      );
      const end = row.contract_info?.end && (
        <span className={contractActive ? styles.contractActive : styles.contractInactive}>
          {moment(row.contract_info?.end).format('DD/MM/YYYY')}
        </span>
      );

      return [bidDate, team, state, type, contract, start, end];
    });

  function athleteHistoricalReducer() {
    if (athleteHistorical && athleteHistorical.length > 0) {
      let result = athleteHistorical;
      let prevSeason = athleteHistorical[0][0];
      let currentSeason = '';
      let arr = [];

      let minutesPlayed = athleteHistorical[0][3];
      let appearances = athleteHistorical[0][4];
      let lineups = athleteHistorical[0][5];
      let subsIn = athleteHistorical[0][6];
      let subsOut = athleteHistorical[0][7];
      let goals = athleteHistorical[0][8];
      let assists = athleteHistorical[0][9];
      let yellowCards = athleteHistorical[0][10];
      let secondYellow = athleteHistorical[0][11];
      let redCards = athleteHistorical[0][12];

      for (let i = 1; i < athleteHistorical.length; i++) {
        currentSeason = athleteHistorical[i][0];

        if (prevSeason === currentSeason) {
          minutesPlayed += athleteHistorical[i][3];
          appearances += athleteHistorical[i][4];
          lineups += athleteHistorical[i][5];
          subsIn += athleteHistorical[i][6];
          subsOut += athleteHistorical[i][7];
          goals += athleteHistorical[i][8];
          assists += athleteHistorical[i][9];
          yellowCards += athleteHistorical[i][10];
          secondYellow += athleteHistorical[i][11];
          redCards += athleteHistorical[i][12];
        } else {
          arr = [
            '',
            '',
            t('club.box9') + prevSeason,
            minutesPlayed,
            appearances,
            lineups,
            subsIn,
            subsOut,
            goals,
            assists,
            yellowCards,
            secondYellow,
            redCards,
          ];
          result.splice(i, 0, arr);

          prevSeason = currentSeason;
          minutesPlayed = 0;
          appearances = 0;
          lineups = 0;
          subsIn = 0;
          subsOut = 0;
          goals = 0;
          assists = 0;
          yellowCards = 0;
          secondYellow = 0;
          redCards = 0;
        }
      }

      let last = result.length - 1;

      minutesPlayed = athleteHistorical[last][3];
      appearances = athleteHistorical[last][4];
      lineups = athleteHistorical[last][5];
      subsIn = athleteHistorical[last][6];
      subsOut = athleteHistorical[last][7];
      goals = athleteHistorical[last][8];
      assists = athleteHistorical[last][9];
      yellowCards = athleteHistorical[last][10];
      secondYellow = athleteHistorical[last][11];
      redCards = athleteHistorical[last][12];

      arr = [
        '',
        '',
        t('club.box9') + currentSeason,
        minutesPlayed,
        appearances,
        lineups,
        subsIn,
        subsOut,
        goals,
        assists,
        yellowCards,
        secondYellow,
        redCards,
      ];
      result.push(arr);

      return result;
    } else return [];
  }

  const athleteHistoricalReduce = athleteHistoricalReducer();
  let athletePositionName;
  if (reportData.shadow_team_athletes?.length > 0 && reportData.shadow_team_positions?.length > 0) {
    const shadowTeamAthlete = reportData.shadow_team_athletes.find(a => a.athlete_data.id === athleteEditableData.id);
    if (shadowTeamAthlete) {
      const shadowTeamPosition = reportData.shadow_team_positions.find(p => p.id === shadowTeamAthlete.position)
        ?.position_name;
      if (shadowTeamPosition) {
        athletePositionName = `${t('report.position')} ${shadowTeamPosition.substring(0, 30)}`;
        // const positionName = `${t('report.position')} ${shadowTeamPosition.substring(0, 30)}`;
        // const positionCode = `${t('report.athlete')} #${shadowTeamAthlete.positionCode ?? ''}`;
        // athletePositionName = `${positionName} | ${positionCode}`;
      }
    }
  }

  const radarChartLabels = fieldsEvaluation ? fieldsEvaluation.map(item => String(item.title)) : [];
  const radarChartSeries = fieldsEvaluation ? fieldsEvaluation.map(item => item.rate || 0) : [];

  const plotOptions = {
    chart: {
      background: 'white',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radar: {
        size: 60,
        polygons: {
          strokeColors: '#ccc',
          connectorColors: '#ccc',
          fill: {
            colors: ['#fff', '#e0e0e0'],
          },
        },
      },
    },
    xaxis: {
      labels: {
        style: { colors: Array(fieldsEvaluation.length).fill('#000'), fontSize: '12px' },
      },
    },
  };

  const radarChartData = {
    labels: radarChartLabels,
    series: [
      {
        data: radarChartSeries,
      },
    ],
  };

  return (
    <>
      <div className={styles.backgroundContainer}></div>
      <div className={[styles.container, 'printable'].join(' ')}>
        <ReportAppBar reportData={reportData} />
        {athletePositionName && <h2 className={styles.athletePositionName}>{athletePositionName}</h2>}
        <div className={styles.allInfo}>
          {athleteMainInfo}
          {(configuration.generalFeatures ||
            configuration.negotiation ||
            configuration.images ||
            configuration.evaluations) && (
            <Card softShadow className={styles.athletePerformance}>
              {configuration.generalFeatures && (
                <div className={styles.generalData}>
                  <label>{t('club.box10')}</label>
                  <span>{athleteEditableData.generalFeatures}</span>
                </div>
              )}
              {configuration.negotiation && (
                <div className={styles.generalData} style={{ display: 'block' }}>
                  <label>{t('club.box11')}</label>
                  <div className={styles.negotiation}>
                    <div className={styles.info}>
                      <span>{t('club.box12')}:</span>
                      {athleteEditableData.negotiation?.agency ?? '-'}
                      <span style={{ marginTop: '10px' }}>{t('contact_card.phone')} 1:</span>
                      {athleteEditableData.negotiation?.agency_phone ? (
                        <div style={{ display: 'flex' }}>
                          <p>{athleteEditableData.negotiation.agency_phone}</p>
                        </div>
                      ) : (
                        '-'
                      )}
                      <span style={{ marginTop: '10px' }}>Whatsapp:</span>
                      {athleteEditableData.negotiation?.agency_whatsapp ? (
                        <div style={{ display: 'flex' }}>
                          <p>{athleteEditableData.negotiation.agency_whatsapp}</p>
                        </div>
                      ) : (
                        '-'
                      )}
                    </div>
                    <div className={styles.inputs}>
                      <span>{t('club.box14')}:</span>
                      {athleteEditableData.negotiation?.investiment ?? '-'}
                      <span style={{ marginTop: '10px' }}>{t('club.box13')}:</span>
                      {athleteEditableData.negotiation?.revenue ?? '-'}
                    </div>
                  </div>
                </div>
              )}

              {configuration.evaluations &&
                (evaluationsData.length > 0 ? (
                  <div className={styles.evaluation}>
                    <div className={styles.generalData}>
                      <label>{t('club.box15')}</label>
                    </div>
                    {fieldsEvaluation.length === 0 ? (
                      ''
                    ) : (
                      <>
                        <div className={styles.evaluationHeaderInfo}>
                          <p>{t('evaluationData')}</p>
                          <div>
                            <FaEye />
                            <span>{athleteEditableData?.author?.name ?? athleteEditableData?.author?.email}</span>
                          </div>
                          <div>
                            <FaRegCalendarAlt />
                            <span>{new Date(athleteEditableData.created_at).toLocaleDateString('pt-BR') || ' '}</span>
                          </div>
                          <div>
                            <IoIosFootball /> <span>{athleteEditableData.method}</span>
                          </div>
                          {athleteEditableData.mode && (
                            <div>
                              <FaFileAlt /> <span>{athleteEditableData.mode}</span>
                            </div>
                          )}
                        </div>
                        {configuration.opinion && (
                          <div className={styles.generalDataEvaluation}>
                            <label className={styles.labelTitle}>{t('field.pare')}</label>
                            <div className={styles.textWrapper}>
                              <CustomFields
                                fields={athleteEditableData.opinion}
                                setFormFields={() => {}}
                                className={styles.customFields}
                                disableAll={true}
                              />
                            </div>
                          </div>
                        )}
                        {configuration.opinion && <div style={{ borderBottom: '1px solid #bdbdbd' }} />}
                        <div className={styles.generalDataEvaluation}>
                          <label className={styles.labelTitle}>{t('club.box15')}</label>
                          <div className={styles.generalEvaluationInside}>
                            <div>
                              <RadarChart height={235} width={300} data={radarChartData} options={plotOptions} />
                            </div>
                            <div className={styles.evaluationResume}>
                              <h1>{t('table.general')}</h1>
                              <div className={styles.generalEvaluationResume}>
                                <StarRating rate={generalRate || 0} size={20} />
                                <span>({generalRate ? generalRate.toFixed(2) : '0.00'})</span>
                              </div>
                              <div style={{ marginTop: '10px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
                                {fieldsEvaluation.map(item => (
                                  <div
                                    style={{ display: 'grid', gridAutoFlow: 'column', gridTemplateColumns: '50% 50%' }}
                                  >
                                    <div>
                                      <p>{item.title}:</p>
                                    </div>
                                    <div className={styles.generalEvaluationResume}>
                                      <StarRating rate={item.rate || 0} size={20} />
                                      <span>({item.rate ? item.rate.toFixed(2) : '0.00'})</span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <FieldList
                            fields={fieldsEvaluation}
                            showFieldsFeedback={configuration.microaspects}
                            enableFieldsFeedbackUpdate={false}
                          />
                        </div>
                        {configuration.background && <div style={{ borderBottom: '1px solid #bdbdbd' }} />}
                        {configuration.background && (
                          <div className={styles.generalDataEvaluation}>
                            <label className={styles.labelTitle}>{t('athleteDashboard.context')}</label>
                            <div className={styles.textWrapper}>
                              <CustomFields
                                fields={athleteEditableData.background}
                                setFormFields={() => {}}
                                className={styles.customFields}
                                disableAll={true}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {user.data?.organization?.teamId === 889 && (
                      <div className={styles.assignsContainer}>
                        <div className={styles.assignsContents}>
                          <div className={styles.assignsContentsLabel}>
                            <p>
                              {t('athleteDashboard.general_manager')}
                              {athleteEditableData.assigns?.gerente_geral &&
                                ` - ${athleteEditableData.assigns?.gerente_geral}`}
                            </p>
                          </div>
                        </div>
                        <div className={styles.assignsContents}>
                          <div className={styles.assignsContentsLabel}>
                            <p>
                              {t('athleteDashboard.coord_technical')}
                              {athleteEditableData.assigns?.coord_tecnico1 &&
                                ` - ${athleteEditableData.assigns?.coord_tecnico1}`}
                            </p>
                          </div>
                        </div>
                        <div className={styles.assignsContents}>
                          <div className={styles.assignsContentsLabel}>
                            <p>
                              {t('athleteDashboard.coord_technical')}
                              {athleteEditableData.assigns?.coord_tecnico2 &&
                                ` - ${athleteEditableData.assigns?.coord_tecnico2}`}
                            </p>
                          </div>
                        </div>
                        <div className={styles.assignsContents}>
                          <div className={styles.assignsContentsLabel}>
                            <p>
                              {t('athleteDashboard.coord_capture')}
                              {athleteEditableData.assigns?.coord_captacao &&
                                ` - ${athleteEditableData.assigns?.coord_captacao}`}
                            </p>
                          </div>
                        </div>
                        <div className={styles.assignsContents}>
                          <div className={styles.assignsContentsLabel}>
                            <p>
                              {t('athleteDashboard.coach')}
                              {athleteEditableData.assigns?.treinador && ` - ${athleteEditableData.assigns?.treinador}`}
                            </p>
                          </div>
                        </div>
                        <div className={styles.assignsContents}>
                          <div className={styles.assignsContentsLabel}>
                            <p>
                              {t('athleteDashboard.prep_of_goalkeepers')}
                              {athleteEditableData.assigns?.prep_goleiros &&
                                ` - ${athleteEditableData.assigns?.prep_goleiros}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={styles.evaluation}>{t('club.box17')}</div>
                ))}
              <div className={styles.imagesContainer}>
                {configuration.images &&
                  athleteEditableData.files.map(item => (
                    <figure key={item.id}>
                      <img src={item.file} alt="teste" />
                      <figcaption>{item.title || t('club.box18')}</figcaption>
                    </figure>
                  ))}
              </div>
            </Card>
          )}
          {configuration.historic && (
            <Card softShadow className={[styles.performanceByTeam, styles.tableCard].join(' ')}>
              <Table
                flat
                fullhead={<h2>{t('club.box19')}</h2>}
                theads={[
                  t('slider_menu.seas'),
                  t('athlete_list.box2'),
                  t('match.comp'),
                  <img src={IcoTempo} alt="tempo" />,
                  <img src={IcoJogos} alt="jogos" />,
                  <img src={IcoTitular} alt="titular" />,
                  <img src={IcoEntrou} alt="entrou" />,
                  <img src={IcoSaiu} alt="saiu" />,
                  <img src={IcoGols} alt="gols" />,
                  <img src={IcoAssistencias} alt="assistências" />,
                  <img src={IcoAmarelos} alt="amarelos" />,
                  <img src={IcoAmareloVermelho} alt="amarelo vermelho" />,
                  <img src={IcoVermelhos} alt="vermelho" />,
                ]}
                data={athleteHistoricalReduce}
              />
            </Card>
          )}

          {configuration.career && athleteTransfers.length > 0 && (
            <Card softShadow className={[styles.transfers, styles.tableCard].join(' ')}>
              <div className={styles.athleteTransfers}>
                <Table
                  fullhead={<h2>{t('club.box20')}</h2>}
                  flat
                  theads={[
                    t('slider_menu.seas'),
                    t('table.data'),
                    t('table.origin'),
                    t('table.destiny'),
                    t('table.age_moment'),
                    t('table.price'),
                  ]}
                  data={athleteTransfers}
                  footer={`${t('athlete_card.text7')} ${athleteTransfersTotal} M €`}
                />
              </div>
            </Card>
          )}

          {configuration.career && (
            <Card softShadow className={[styles.contracts, styles.tableCard].join(' ')}>
              <div className={styles.athleteContracts}>
                <Table
                  fullhead={<h2>{t('athlete_card.text1')}</h2>}
                  flat
                  theads={[
                    t('athlete_card.text2'),
                    t('athlete_list.box2'),
                    t('athlete_card.text5'),
                    t('overview.text15'),
                    t('filters.contract'),
                    t('athlete_card.text3'),
                    t('athlete_card.text4'),
                  ]}
                  data={athleteContracts}
                />
              </div>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

Preview.propTypes = {
  configuration: PropTypes.instanceOf(Object).isRequired,
  athleteEditableData: PropTypes.instanceOf(Object).isRequired,
  athlete: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Preview);
