import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import Filter from './Filter/Filter';
import AthleteList from './AthleteList/AthleteList';
import { usePagination } from 'shared/hooks';
import { getAthletes, countAthletesInTransferMarket } from 'store/ducks/athleteV2';
import styles from './AdvancedSearch.module.scss';
import { TopBar, Pagination } from 'components/template';
import ReportDialog from 'components/ReportDialog/ReportDialog';
import { useTranslation } from 'react-i18next';
import Variables from 'variables';

const options = [
  {
    label: 20,
    value: 20,
  },
  {
    label: 30,
    value: 30,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 150,
    value: 150,
  },
  {
    label: 1000,
    value: 1000,
  },
];

function AdvancedSearch() {
  const dispatch = useDispatch();

  const athletes = useSelector(state => state.athleteV2.athletes);

  const user = useSelector(state => state.user);

  const { t } = useTranslation();

  const [pagination, setPagination, defaultPagination] = usePagination();
  const [queryFilters, setQueryFilters] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [countries, setCountries] = useState([]);
  const [countInTransferMarket, setCountInTransferMarket] = useState(0);

  const mounted = useRef(false);

  const load_athletes = async (filters, load_transfermarket) => {
    dispatch(getAthletes(filters));
    if (load_transfermarket && user?.data?.organization?.type === 'team') {
      const count = await countAthletesInTransferMarket(filters);
      if (count !== countInTransferMarket) {
        setCountInTransferMarket(count);
      }
    }
  };

  useEffect(() => {
    const redirect = JSON.parse(sessionStorage.getItem('redirect'));
    const advancedSearch = JSON.parse(sessionStorage.getItem('advanced_search'));

    if (mounted.current || (!mounted.current && !(advancedSearch || redirect))) {
      setPagination(defaultPagination);
      load_athletes({ ...queryFilters, ...defaultPagination }, true);
    }

    mounted.current = true;

    sessionStorage.removeItem('redirect');
  }, [dispatch, queryFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSetQueryFilters = filters => {
    let emptyObj;

    for (let filter in filters) {
      if (Array.isArray(filters[filter]) && filters[filter].length === 0) {
        delete filters[filter];
      } else if (typeof filters[filter] === 'object') {
        emptyObj = true;

        for (let key in filters[filter]) {
          if (filters[filter][key] !== '') {
            emptyObj = false;
          }
        }

        if (emptyObj) {
          delete filters[filter];
        }
      } else if (filters[filter] === '') {
        delete filters[filter];
      } else if (filter === 'watched' && !filters[filter]) {
        delete filters[filter];
      }
    }
    setActiveFilters(filters);
  };

  const handlePagination = newPagination => {
    setPagination(newPagination);
    load_athletes({ ...queryFilters, ...newPagination }, false);
  };

  return (
    <div className={styles.container}>
      <>
        <TopBar
          title={t('filters.search')}
          subtitle={t('filters.ath_base')}
          icon={<FaSearch />}
          iconBgColor={Variables.pink1}
          className={styles.TopBar}
        />
        <div className={styles.content}>
          <ReportDialog isOpen={isOpenDialog} setIsOpen={setIsOpenDialog} />
          {/* <button
                    style={{ marginTop: 100 }}
                    onClick={(e) => setIsOpenDialog(true)}
                >Clica aqui!</button> */}
          <Filter
            countries={countries}
            setCountries={setCountries}
            setQueryFilters={setQueryFilters}
            onSetQueryFilters={onSetQueryFilters}
            activeFilters={activeFilters}
            overlay={athletes.loading}
            className={styles.filter}
            autoSearch={false}
          />
          <div className={styles.tableContainer}>
            <AthleteList
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              countries={countries}
              athletes={athletes}
              user={user}
              queryFilters={queryFilters}
              countInTransferMarket={countInTransferMarket}
            />
            {/* <ActiveFilters
            setActiveFilters={setActiveFilters}
            activeFilters={activeFilters}
            countries={countries}
            overlay={athletes.loading}
          /> */}
            <Pagination
              options={options}
              length={athletes.data.length}
              left={315}
              hasNext={athletes.hasNext}
              hasPrevious={athletes.hasPrevious}
              pagination={pagination}
              count={athletes.count}
              setPagination={handlePagination}
              resourceDisplayName={t('filters.ath')}
            />
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(AdvancedSearch);
