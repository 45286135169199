/*eslint-disable eqeqeq*/
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IcoTempo from 'assets/ico/ico-tempo.png';
import IcoJogos from 'assets/ico/ico-jogos.png';
import IcoTitular from 'assets/ico/ico-titular.png';
import IcoEntrou from 'assets/ico/ico-entrou.png';
import IcoSaiu from 'assets/ico/ico-saiu.png';
import IcoGols from 'assets/ico/ico-gols.png';
import IcoAssistencias from 'assets/ico/ico-assistencias.png';
import IcoAmarelos from 'assets/ico/ico-amarelos.png';
import IcoAmareloVermelho from 'assets/ico/ico-amarelo-vermelho.png';
import IcoVermelhos from 'assets/ico/ico-vermelhos.png';
import IcoBanco from 'assets/ico/ico-banco.png';
import { usePagination } from 'shared/hooks';
import { Table, Pagination, Spinner, Card, ToolTip, Checkbox } from 'components/template';
import styles from './Subscribed.module.scss';
import InfoBar from './InfoBar/InfoBar';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscribed, Creators } from 'store/ducks/competition';
import TopBar from 'pages/Club/TopBar/TopBar';
import TeamRow from 'components/TeamRow/TeamRow';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import CountryRow from 'components/CountryRow/CountryRow';
import MonitoringButton from 'components/MonitoringButton/MonitoringButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import Match from './Match';
import { useTranslation } from 'react-i18next';
import ActionBar from './ActionBar/ActionBar';

export default function Subscribed({ id }) {
  const { t } = useTranslation();

  const { teamId } = useParams();

  const dispatch = useDispatch();
  const competition = useSelector(state => state.competition.competitionsPage);
  const subscribers = useSelector(state => state.competition.subscribed);
  const matches = useSelector(state => state.competition.matches);
  const matchesData = matches.data.matches;
  const matchesDataIsNotEmpty = (matchesData?.length ?? 0) > 0;

  const [pagination, setPagination] = usePagination();

  const [minimunDate, setMinimunDate] = useState('');
  const [checkedAthletes, setCheckedAthletes] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    name: '',
    position: [],
    max_birthyear: null,
    min_birthyear: null,
  });

  let team;
  if (subscribers?.athletes[0]?.team) {
    team = subscribers?.athletes[0]?.team;
  } else if (matchesDataIsNotEmpty) {
    if (matchesData[0].home_team?.id == teamId) {
      team = matchesData[0].home_team;
    } else if (matchesData[0].visiting_team?.id == teamId) {
      team = matchesData[0].visiting_team;
    }
  }
  team = team ?? {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getSubscribed(id, teamId, { ...pagination }));
        dispatch(Creators.getMatches(id, { team: teamId }));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setMinimunDate(getLowestBirthYear(subscribers.athletes));
      }
    };

    fetchData();

    return () => {
      dispatch(Creators.resetMatches());
    };
  }, [dispatch, id, teamId]);

  useEffect(() => {
    const fetchFilteredData = async () => {
      try {
        dispatch(getSubscribed(id, teamId, { ...selectedFilters, ...pagination }));
      } catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    };

    fetchFilteredData();
  }, [dispatch, id, teamId, selectedFilters, pagination]);

  useEffect(() => {
    if (allChecked) {
      setCheckedAthletes(subscribers.athletes.map(item => ({ id: item.athlete.id, name: item.athlete.fullname })));
    } else {
      setCheckedAthletes([]);
    }
  }, [allChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckAthlete = athlete => {
    if (checkedAthletes.some(item => item.id === athlete.id)) {
      setCheckedAthletes(checkedAthletes.filter(item => item.id !== athlete.id));
    } else {
      setCheckedAthletes([...checkedAthletes, athlete]);
    }
  };

  const filterData = data => {
    const filteredData = data.map(register => {
      const checkbox = (
        <Checkbox
          dark
          checked={checkedAthletes.some(athlete => athlete.id === register?.athlete.id)}
          onChange={() => {
            handleCheckAthlete({ name: register?.athlete.fullname, id: register?.athlete.id });
          }}
        />
      );
      const player = (
        <AthleteRow
          id={register.athlete.id}
          img={register.athlete.photo}
          name={register.athlete.fullname}
          nickname={register.athlete?.nickname}
        />
      ); // birthyear={register.athlete.birthyear}

      const currentTeam = (
        <TeamRow
          name={register.athlete.current_team?.nickname}
          id={register.athlete.current_team?.id}
          img={register.athlete.current_team?.emblem}
        />
      );
      const srcTeam = register?.athlete.src_team && (
        <TeamRow
          name={register?.athlete.src_team?.nickname}
          id={register?.athlete.src_team?.id}
          img={register?.athlete.src_team?.emblem}
        />
      );

      const age = (
        <span style={{ fontWeight: '500' }}>
          '{register.athlete?.birthyear?.toString().substr(-2)} &#40;{register.athlete?.age}&#41;
        </span>
      );
      const position = register.athlete?.position ? t(`positionOptionsRaw.${register.athlete?.position}`) : '-';
      const nationality =
        register &&
        register.athlete &&
        register.athlete.nationalities &&
        register.athlete.nationalities.length > 0 &&
        register.athlete.nationalities.map((item, key) => <CountryRow key={key} id={key} img={item.flag} />);
      const minutesPlayed = register.minutes_played;
      const appearances = register.appearances;
      const lineups = register.lineups;
      const onBench = register.on_bench;
      const subsIn = register.subs_in;
      const subsOut = register.subs_out;
      const goals = register.goals;
      const assists = register.assists;
      const yellowCards = register.yellow_cards;
      const secondYellow = register.second_yellow_cards;
      const redCards = register.red_cards;

      const monitoringButton = (
        <div className={styles.buttonContainer}>
          <MonitoringButton athlete={register.athlete} />
        </div>
      );

      return [
        checkbox,
        player,
        currentTeam,
        srcTeam,
        age,
        position,
        nationality,
        goals,
        minutesPlayed,
        appearances,
        lineups,
        onBench,
        subsIn,
        subsOut,
        assists,
        yellowCards,
        secondYellow,
        redCards,
        monitoringButton,
      ];
    });

    return filteredData;
  };

  const extractMaxAge = category => {
    const categoryAge = parseInt(category.replace(/\D/g, ''));
    if (categoryAge === 14 || categoryAge === 13) {
      return parseInt(categoryAge, 10) - 1;
    } else if (parseInt(categoryAge) <= 17) {
      return parseInt(categoryAge, 10) - 2;
    }
    return parseInt(categoryAge, 10) - 3;
  };

  function getLowestBirthYear(data) {
    if (!Array.isArray(data) || data.length === 0) {
      return null; // Retorna null se o array estiver vazio ou não for válido
    }

    return data.reduce((lowestYear, item) => {
      const birthYear = item?.athlete?.birthyear;
      if (birthYear && (lowestYear === null || birthYear < lowestYear)) {
        return birthYear;
      }
      return lowestYear;
    }, null);
  }

  let content;

  if (!subscribers.error) {
    const css = `
            .swiper-button-prev {
                left: 0;
                font-weight: 900;
            }
            .swiper-button-next {
                right: 0;
                font-weight: 900;                
            }
        `;
    if (parseInt(competition.data.matches_count) > 0) {
      content = (
        <>
          <div className={styles.container}>
            <TopBar club={team} />
            <div className={styles.content}>
              <InfoBar competition={competition.data} />
              <div className={styles.matches}>
                <style>{css}</style>
                <Swiper
                  slidesPerView={4}
                  navigation
                  modules={[Navigation]}
                  style={{
                    '--swiper-navigation-color': '#88B45B',
                    '--swiper-navigation-size': '14px',
                  }}
                >
                  {matchesDataIsNotEmpty ? (
                    matchesData.map(match => {
                      return (
                        <SwiperSlide>
                          <div style={{ margin: '0 15px 0 15px' }}>
                            <Match match={match} teamLink={'/clube/$id/elenco'} />
                          </div>
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <p style={{ textAlign: 'center', margin: '40px' }}>
                      <i>{t('compe.text16')}</i>
                    </p>
                  )}
                </Swiper>
              </div>
              <h3>{t('compe.text20')}</h3>
              <Card softShadow className={styles.athleteList}>
                <div className={styles.tableContainer}>
                  <ActionBar
                    checkedAthletes={checkedAthletes}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    category={competition.data?.category}
                    season={competition.data?.season}
                    maxAge={extractMaxAge(competition.data?.category)}
                    minimunDate={minimunDate}
                  />
                  {subscribers.loading ? (
                    <div className={styles.loaderContainer}>
                      <Spinner />
                    </div>
                  ) : (
                    <Table
                      className={styles.subscribed_table}
                      flat
                      theads={[
                        <Checkbox
                          dark
                          checked={allChecked}
                          onChange={() => setAllChecked(!allChecked)}
                          className={styles.checkbox}
                        />,
                        t('athlete_list.box1'),
                        t('agencies.text2'),
                        t('compe.text18'),
                        t('athlete_list.box3'),
                        t('athlete_list.box4'),
                        t('athlete_list.box5'),
                        <ToolTip message={t('match.text9')}>
                          <img src={IcoGols} alt="gols" />
                        </ToolTip>,
                        <ToolTip message={t('match.text13')}>
                          <img src={IcoTempo} alt="tempo" />
                        </ToolTip>,
                        <ToolTip message={t('match.text18')}>
                          <img src={IcoJogos} alt="jogos" />
                        </ToolTip>,
                        <ToolTip message={t('match.text14')}>
                          <img src={IcoTitular} alt="titular" />
                        </ToolTip>,
                        <ToolTip message={t('match.text15')}>
                          <img src={IcoBanco} alt="banco" />
                        </ToolTip>,
                        <ToolTip message={t('match.text20')}>
                          <img src={IcoEntrou} alt="entrou" />
                        </ToolTip>,
                        <ToolTip message={t('match.text21')}>
                          <img src={IcoSaiu} alt="saiu" />
                        </ToolTip>,
                        <ToolTip message={t('match.text22')}>
                          <img src={IcoAssistencias} alt="assistências" />
                        </ToolTip>,
                        <ToolTip message={t('match.text10')}>
                          <img src={IcoAmarelos} alt="amarelos" />
                        </ToolTip>,
                        <ToolTip message={t('match.text19')}>
                          <img src={IcoAmareloVermelho} alt="amarelo vermelho" />
                        </ToolTip>,
                        <ToolTip message={t('match.text12')}>
                          <img src={IcoVermelhos} alt="vermelho" />
                        </ToolTip>,
                        '',
                      ]}
                      data={filterData(subscribers.athletes)}
                    />
                  )}
                </div>
              </Card>
              <Pagination
                length={subscribers.athletes.length}
                count={subscribers.count}
                pagination={pagination}
                setPagination={setPagination}
                hasNext={subscribers.hasNext}
                hasPrevious={subscribers.hasPrevious}
                resourceDisplayName={t('compe.text19')}
              />
            </div>
          </div>
        </>
      );
    } else {
      content = (
        <>
          <div className={styles.container}>
            <TopBar club={team} />
            <div className={styles.content}>
              <InfoBar competition={competition.data} />
              <Table
                className={styles.subscribed_table}
                flat
                theads={[
                  t('athlete_list.box1'),
                  t('agencies.text2'),
                  t('compe.text18'),
                  t('athlete_list.box3'),
                  t('athlete_list.box4'),
                  t('athlete_list.box5'),
                  <ToolTip message={t('match.text9')}>
                    <img src={IcoGols} alt="gols" />
                  </ToolTip>,
                  <ToolTip message={t('match.text13')}>
                    <img src={IcoTempo} alt="tempo" />
                  </ToolTip>,
                  <ToolTip message={t('match.text18')}>
                    <img src={IcoJogos} alt="jogos" />
                  </ToolTip>,
                  <ToolTip message={t('match.text14')}>
                    <img src={IcoTitular} alt="titular" />
                  </ToolTip>,
                  <ToolTip message={t('match.text15')}>
                    <img src={IcoBanco} alt="banco" />
                  </ToolTip>,
                  <ToolTip message={t('match.text20')}>
                    <img src={IcoEntrou} alt="entrou" />
                  </ToolTip>,
                  <ToolTip message={t('match.text21')}>
                    <img src={IcoSaiu} alt="saiu" />
                  </ToolTip>,
                  <ToolTip message={t('match.text22')}>
                    <img src={IcoAssistencias} alt="assistências" />
                  </ToolTip>,
                  <ToolTip message={t('match.text10')}>
                    <img src={IcoAmarelos} alt="amarelos" />
                  </ToolTip>,
                  <ToolTip message={t('match.text19')}>
                    <img src={IcoAmareloVermelho} alt="amarelo vermelho" />
                  </ToolTip>,
                  <ToolTip message={t('match.text12')}>
                    <img src={IcoVermelhos} alt="vermelho" />
                  </ToolTip>,
                  '',
                ]}
                data={filterData(subscribers.athletes)}
              />
              <Pagination
                length={subscribers.athletes.length}
                count={subscribers.count}
                pagination={pagination}
                setPagination={setPagination}
                hasNext={subscribers.hasNext}
                hasPrevious={subscribers.hasPrevious}
                resourceDisplayName={t('compe.text19')}
              />
            </div>
          </div>
        </>
      );
    }
  } else {
    content = (
      <Card className={styles.errorContainer}>
        <h2>{t(subscribers.error)}</h2>
      </Card>
    );
  }

  return (
    // <Card softShadow>
    <>
      {matches.loading ? (
        <div className={styles.loaderContainer}>
          <Spinner />
        </div>
      ) : (
        content
      )}
    </>
  );
}
