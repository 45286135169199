import React, { useState, useEffect } from 'react';

import { FaSearch, FaClipboardList } from 'react-icons/fa';
import { Input, Menu, SliderMenu, Button, Checkbox } from 'components/template';

import styles from './ActionBar.module.scss';
import { positionOptions } from 'shared/mocks';
import { useTranslation } from 'react-i18next';

export default function ActionBar({
  checkedAthletes,
  selectedFilters,
  setSelectedFilters,
  season,
  maxAge,
  minimunDate,
}) {
  const { t } = useTranslation();

  const [timeoutId, setTimeoutId] = useState(null);
  const [name, setName] = useState('');
  const [checkboxActive, setCheckboxActive] = useState(false);

  const handleCheckboxChange = () => {
    setCheckboxActive(prev => {
      const newCheckboxState = !prev;
      setSelectedFilters(prevFilters => ({
        ...prevFilters,
        max_birthyear: null,
        min_birthyear: newCheckboxState ? season - maxAge : null,
      }));

      return newCheckboxState;
    });
  };

  const handleInputChange = value => {
    clearTimeout(timeoutId);
    setName(value);
    setTimeoutId(
      setTimeout(() => {
        setSelectedFilters({ ...selectedFilters, name: value });
      }, 200)
    );
  };

  return (
    <div className={styles.actionBar}>
      <div className={styles.actionButtons}>
        <Button
          className={styles.iconButton}
          onClick={() => window.open(`/relatorios/criar?ids=${checkedAthletes.map(athlete => athlete.id).join(',')}`)}
          color="vividGreen"
          disabled={checkedAthletes.length === 0}
        >
          <div className={styles.text}>
            {t('athlete_list.tip')}
            <FaClipboardList className={styles.icon} />
          </div>
        </Button>
      </div>
      <Input
        rounded
        placeholder={t('user.text31')}
        containerClassName={styles.inputContainer}
        onChange={e => {
          handleInputChange(e.target.value);
        }}
        icon={<FaSearch className={styles.icon} />}
        iconPosition="right"
        value={name}
      />
      <Menu
        title={t('charts.filterByPosition')}
        largeMenu
        multiple
        rounded
        allowEmpty
        placeholder={t('charts.filterByPosition')}
        fixedPlaceholder
        className={styles.menu}
        options={positionOptions.map(item => ({ label: t(`positionOptions.${item.value}`), value: item.label }))}
        value={selectedFilters.position}
        onClear={() => {
          setSelectedFilters({ ...selectedFilters, position: [] });
        }}
        setValue={val => {
          setSelectedFilters({ ...selectedFilters, position: val });
        }}
      />
      {checkboxActive ? (
        <></>
      ) : (
        <SliderMenu
          rounded
          title={t('criteriaOptions.birthyear')}
          className={styles.menu}
          max={Number((() => new Date())().getFullYear())}
          min={1975}
          setFilter={val => {
            const [min, max] = val;
            setCheckboxActive(false);
            setSelectedFilters({ ...selectedFilters, min_birthyear: min, max_birthyear: max });
          }}
          onClear={() => {
            setCheckboxActive(false);
            setSelectedFilters({
              ...selectedFilters,
              max_birthyear: null,
              min_birthyear: null,
            });
          }}
        />
      )}
      <div className={styles.checkBox}>
        <Checkbox dark checked={checkboxActive} onChange={handleCheckboxChange} />
        <span>Somente Atletas Precoces</span>
      </div>
    </div>
  );
}
